import { Injectable, EventEmitter } from '@angular/core';
import { Http, Response, Headers } from '@angular/http';
import 'rxjs/add/operator/map';
import { Observable } from 'rxjs/Observable';
import { map } from 'rxjs/operators';
import { utils } from '../../utils/utils';
import * as $ from 'jquery';
import { Ruta } from '../../models/ruta';
import { archivoRoutech } from '../../models/archivoRoutech'

@Injectable()
export class RutaService {
  // public usuario:Array<any>;
  public headers = new Headers({ 'Content-Type': 'application/json; charset=utf-8', 'Accept': 'application/json, text/javascript, /;' });
  //public headers = new Headers({'Content-Type': 'application/json; charset=utf-8'});


  constructor(private _http: Http, private _utils: utils) {
    this._utils = new utils();
  }

  exportarPDF(listaParadas) {

    const json = JSON.stringify(listaParadas);

    return this._http
      .post(`${this._utils.url2}Ruta/exportarPDF/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            console.log(response.text());
            return response.text();
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }

  consultarRelaciones(params): Observable<any[]> {
    let json = JSON.stringify(params);

    return this._http.post(`${this._utils.url}Publicaciones/consultarRelaciones`, json, {headers: this.headers}).pipe(
    map((res: any) => {
        try {
          return JSON.parse(res.text());
        }
        catch (error){
            console.log(error);
            return null;
        }  
    }));
  }

  consultarEvidenciasRelacion(params): Observable<any[]> {
    const json = JSON.stringify(params);

    return this._http.post(`${this._utils.url}Publicaciones/consultarEvidencias`, json,  {headers: this.headers}).pipe(
    map((res: any) => {
        try {
            return JSON.parse(res.text());
        }
        catch (error){
            console.log(error);
            return null;
        }  
    }));
}


  consultarParadasVisitadas(idRuta): Observable<any[]> {
    let params = {
      idRuta: idRuta
    }
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(params);

    return this._http
      .post(`${this._utils.url}Ruta/consultarRutaParadasVisitadas/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  descargarEvidenciaPDF(idTransportistaCartaPagoArchivo): Observable<any> {
    const formData = new FormData();
    formData.append('idTransportistaCartaPagoArchivo', idTransportistaCartaPagoArchivo);

    return this._http.post(`${this._utils.url}Publicaciones/descargarEvidenciaPDF`, formData).pipe(
        map((res: any) => {
            return res.json();
        }));
  }

  /* consultarRuta(idRuta: number): Observable<Ruta> {
     // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    var ruta = new Ruta(0,"");
    pais.idPais = idPais;
    const json = JSON.stringify(pais);
       const params = json;
     return this._http
       .post(`${this._utils.url}Pais/consultarPais/`,params, {headers: this.headers} )
       .map((response) => {
         try {
           if (response.json() !== 'null') {
             return JSON.parse(response.text());
           } else {
             return null;
           }
         } catch (e) {
           return null;
         }
       });
   }*/

  consultarRutas(): Observable<Ruta[]> {
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    return this._http
      .get(`${this._utils.url}Ruta/consultarRutas`)
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarRutasModal(obj): Observable<Ruta[]> {
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const params = JSON.stringify(obj);
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    return this._http
      .post(`${this._utils.url}Ruta/consultarRutasModal/`, params, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarRuta(idRuta): Observable<any> {
    let params = {
      idRuta: idRuta
    }
    const json = JSON.stringify(params);
    return this._http
      .post(`${this._utils.url}Ruta/consultarRuta/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  cancelarRuta(params:any){
    console.log(params)
    const json = JSON.stringify(params);
    console.log(json);
    return this._http
      .post(`${this._utils.url}Ruta/cancelarRuta/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarRutasOperaciones(fechaInicio: string, fechaFin: string, idRazonSocial: any, idUsuario: any,filtro: string): Observable<any[]> {
    return this._http
      .get(`${this._utils.url}Ruta/consultarRutasOperaciones?fechaInicio=` + fechaInicio + `&fechaFin=` + fechaFin + `&idRazonSocial=` + idRazonSocial + `&idUsuario=` + idUsuario + `&filtro=` + filtro )
      .map((response) => {
        try {
          if (response.json() !== null) {
            return response.json();
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarRelacionesConfirmarDocumentos(fechaInicio: string, fechaFin: string, idRazonSocial: any, idUsuario: any): Observable<any[]> {
    return this._http
      .get(`${this._utils.url}Ruta/consultarRutasConfirmarDocumentos?fechaInicio=` + fechaInicio + `&fechaFin=` + fechaFin + `&idRazonSocial=` + idRazonSocial + `&idUsuario=` + idUsuario)
      .map((response) => {
        try {
          if (response.json() !== null) {
            return response.json();
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  
  consultarRelacionesPlanner(params): Observable<any[]> {
    let json = JSON.stringify(params);

    return this._http.post(`${this._utils.url}Publicaciones/consultarRelacionesPlanner`, json, {headers: this.headers}).pipe(
    map((res: any) => {
        try {
          return JSON.parse(res.text());
        }
        catch (error){
            console.log(error);
            return null;
        }  
    }));
  }


  consultarVehiculos(idRazonSocial): Observable<any[]> {
    let params = {
      idRazonSocial: idRazonSocial
    }
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString());
    const json = JSON.stringify(params);

    return this._http
      .post(`${this._utils.url}Ruta/consultarVehiculos/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
           // console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarKitUnidad(idVehiculo): Observable<any[]> {
    let params = {
      idVehiculo: idVehiculo
    }
    const json = JSON.stringify(params);
    return this._http
      .post(`${this._utils.url}Ruta/consultarKitUnidad/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarRemolques(idRazonSocial): Observable<any[]> {
    let params = {
      idRazonSocial: idRazonSocial
    }
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString());
    const json = JSON.stringify(params);

    return this._http
      .post(`${this._utils.url}Ruta/consultarRemolques/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  cargarTransportistaPreferente(idCliente): Observable<any> {
    return this._http
      .get(`${this._utils.url}Ruta/consultarTransportistaPreferente?idCliente=` + idCliente)
      .map((response) => {
        try {
          if (response.json() !== null) {
            return response.json();
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }


  autorizarRuta(idRuta: number): Observable<any[]> {
    return this._http
      .get(`${this._utils.url}Ruta/autorizarRuta?idRuta=` + idRuta)
      .map((response) => {
        try {
          if (response.json() !== null) {
            return response.json();
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarRutasMonitor(fechaInicio: string, fechaFin: string, idRazonSocial: any,filtro:string): Observable<any> {
    return this._http
      .get(`${this._utils.url}Ruta/consultarRutasMonitor?fechaInicio=` + fechaInicio + `&fechaFin=` + fechaFin + `&idRazonSocial=` + idRazonSocial +`&filtro=`+filtro)
      .map((response) => {
        try {
          if (response.json() !== null) {
            return response.json();
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }


  consultarRutaEventos(idRuta: number): Observable<any[]> {
    return this._http
      .get(`${this._utils.url}Ruta/consultarRutaEventos?idRuta=` + idRuta)
      .map((response) => {
        try {
          if (response.json() !== null) {
            return response.json();
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarRutaEvidencias(idRuta: number): Observable<any[]> {
    return this._http
      .get(`${this._utils.url}Ruta/consultarRutaEvidencias?idRuta=` + idRuta)
      .map((response) => {
        try {
          if (response.json() !== null) {
            return response.json();
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  confirmarArchivosFacturas(idRuta: number, confirmar: number): Observable<any[]> {
    return this._http
      .get(`${this._utils.url}Ruta/confirmarArchivosFacturas?idRuta=` + idRuta + '&confirmar=' + confirmar )
      .map((response) => {
        try {
          if (response.json() !== null) {
            return response.json();
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarArchivoBolsaTransportista(idRutaTransportista: number, tipoArchivo: number, idUsuario: number): Observable<any[]> {
    return this._http
      .get(`${this._utils.url}Ruta/consultarArchivosBolsaTransportista?idRutaTransportista=` + idRutaTransportista + '&tipoArchivo=' + tipoArchivo + '&idUsuario=' + idUsuario)
      .map((response) => {
        try {
          if (response.json() !== null) {
            return response.json();
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarRutaViajeZAM(idRuta: number): Observable<any[]> {
    return this._http
      .get(`${this._utils.url}Ruta/consultarRutaViajeZAM?idRuta=` + idRuta)
      .map((response) => {
        try {
          if (response.json() !== null) {
            return response.json();
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  eliminarArchivo(idTransportistaRutaArchivo: number): Observable<any[]> {
    return this._http
      .get(`${this._utils.url}Ruta/eliminarArchivoBolsaTransportista?idTransportistaRutaArchivo=` + idTransportistaRutaArchivo)
      .map((response) => {
        try {
          if (response.json() !== null) {
            return response.json();
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  guardarArchivo(file: File): Observable<any> {
    const formData = new FormData();
    formData.append('archivo', file);
    // formData.append('idRazonSocial', idRazonSocial);
    // formData.append('idUsuario', idUsuario);

    return this._http.post(`${this._utils.url}PortalEmbarcadorSolicitud/importarSolicitudEmbarquesXML`, formData).pipe(
    map((res: any) => {
        return JSON.parse(res.json());
    }));
}

  consultarRutaCoordenada(idRuta: number): Observable<any[]> {
    return this._http
      .get(`${this._utils.url}Ruta/consultarRutaCoordenada?idRuta=` + idRuta)
      .map((response) => {
        try {
          if (response.json() !== null) {
            return response.json();
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  agregarRuta(ruta) {
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(ruta);
    const params = json;
    return this._http
      .post(`${this._utils.url}Ruta/agregarRuta/`, params, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            console.log(response.text());
            return response.text();
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }

  agregarFolioRuta(ruta) {
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(ruta);
    const params = json;
    return this._http
      .post(`${this._utils.url}Ruta/agregarFolioRuta/`, params, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }

  asignarRuta(listaSegmentos: any) {

    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(listaSegmentos);

    return this._http
      .post(`${this._utils.url}Ruta/asignarRuta/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            console.log(response.text());
            return response.text();
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }

  consultarTipoOperaciones(idRazonSocial: any) {
    let params = {
      idRazonSocial: idRazonSocial
    }
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(params);

    return this._http
      .post(`${this._utils.url}Ruta/consultarTipoOperaciones/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }

  consultarTipoOperacion(params: any) {

    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(params);

    return this._http
      .post(`${this._utils.url}Ruta/consultarTipoOperacion/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }

  agregarTipoOperacion(params) {

    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(params);

    return this._http
      .post(`${this._utils.url}Ruta/agregarTipoOperacion/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            console.log(response.text());
            return response.text();
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }

  editarTipoOperacion(params) {

    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(params);

    return this._http
      .post(`${this._utils.url}Ruta/editarTipoOperacion/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            console.log(response.text());
            return response.text();
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }


  consultarConvenios(idRazonSocial: any) {
    let params = {
      idRazonSocial: idRazonSocial
    }
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(params);

    return this._http
      .post(`${this._utils.url}Ruta/consultarConvenios/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }

  consultarRutasExternas(idRazonSocial: number): Observable<any[]> {
    let params = {
      idRazonSocial: idRazonSocial
    }
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(params);

    return this._http
      .post(`${this._utils.url}Ruta/consultarRutasExternas/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            //console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }

  cargarSegmentos(idRuta): Observable<any[]> {
    let params = {
      idRuta: idRuta
    }
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(params);

    return this._http
      .post(`${this._utils.url}Ruta/cargarSegmentos/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarPedidoRutaZAM(idRuta) {
    let params = {
      idRuta: idRuta
    }
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(params);

    return this._http
      .post(`${this._utils.url}Ruta/consultarPedidoRutaZAM/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            console.log(response.text());
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  agregarConvenio(descripcion: any, idRazonSocial: any) {
    let params = {
      descripcion: descripcion,
      idRazonSocial: idRazonSocial
    }
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(params);

    return this._http
      .post(`${this._utils.url}Ruta/agregarConvenio/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            console.log(response.text());
            return response.text();
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }

  actualizarRuta(ruta: any) {
    // let params = new URLSearchParams();
    // params.append( 'idPerfilAcceso', idPerfilAcceso.toString() );
    const json = JSON.stringify(ruta);
    const params = json;
    return this._http
      .post(`${this._utils.url}Ruta/actualizarRuta/`, params, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            console.log(response.text());
            return response.statusText;
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }

  actualizarRutaEstatus(ruta: any) {
    const json = JSON.stringify(ruta);

    return this._http
      .post(`${this._utils.url}Ruta/actualizarRutaEstatus/`, json, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            return response.statusText;
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }

  actualizarSegmentoRutaDescargado(idRuta, descargado) {
    return this._http
      .get(`${this._utils.url}Ruta/actualizarSegmentoRutaDescargado?idRuta=`+idRuta+`&descargado=`+descargado, { headers: this.headers })
      .map((response) => {
        try {
          if (response.statusText !== 'null' && response.statusText == 'OK') {
            return response.statusText;
          } else {
            return null;
          }
        } catch (error) {
          console.log(error);
          return null;
        }
      });
  }

  consultarRutasCancelacion(idRazonSocial): Observable<Ruta[]> {
    let json = {
      idRazonSocial: idRazonSocial
    }
    let params = JSON.stringify(json);
    return this._http
      .post(`${this._utils.url}Ruta/consultarRutas_cancelacion`, params, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  cancelarRutas(rutas): Observable<Ruta[]> {
    let json = rutas;
    let params = JSON.stringify(json);
    return this._http
      .post(`${this._utils.url}Ruta/cancelarRutas`, params, { headers: this.headers })
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  consultarRutaTransportistaDatosAsignacion(id): Observable<any[]> {
    return this._http.get(`${this._utils.url}Ruta/consultarRutaTransportistaDatosAsignacion?idRuta=`+id).map(
      (response) => {
        try {
          if (response.json() !== 'null') {
            return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  /* actualizarPais(pais: Pais) {
       const json = JSON.stringify(pais);
       const params = json;
       return this._http
         .post(`${this._utils.url}Pais/actualizarPais/`, params, {headers: this.headers} )
         .map((response) => {
           try {
             if(response.statusText !== 'null' && response.statusText == 'OK'){
               console.log(response.text());
               return response.statusText;
             }else{
               return null;
             }
           } catch (error) {
             console.log(error);
             return null; 
           } 
         });
   }*/

   descargarFiles(idRuta, tipoArchivo, idRazonSocial): Observable<any> {
    const formData = new FormData();
    formData.append('idRuta', idRuta);
    formData.append('tipoArchivo', tipoArchivo);
    formData.append('idRazonSocial', idRazonSocial);

    return this._http.post(`${this._utils.url}Transportista/descargarArchivos`, formData).pipe(
      map((res: any) => {
        return res.json();
      }));
  }

  descargarFilesRelacion(idTransportistaCartaPago, tipoArchivo, idTransportistaPortal): Observable<any> {
    const formData = new FormData();
    formData.append('idTransportistaCartaPago', idTransportistaCartaPago);
    formData.append('tipoArchivo', tipoArchivo);
    formData.append('idTransportistaPortal', idTransportistaPortal);

    return this._http.post(`${this._utils.url}Publicaciones/descargarArchivos`, formData).pipe(
      map((res: any) => {
        return res.json();
      }));
  }


  rechazarDocumentos(params) {
    const json = JSON.stringify(params);
    
    return this._http
      .post(`${this._utils.url}Transportista/rechazarDocumentos` ,json, {headers: this.headers})
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            console.log(response.text());
          return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  actualizarMontoFacturas(params) {
    const json = JSON.stringify(params);
    
    return this._http
      .post(`${this._utils.url}Transportista/actualizarMontosFacturas` ,json, {headers: this.headers})
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            console.log(response.text());
          return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  confirmarDocumentos(params) {
    const json = JSON.stringify(params);
    
    return this._http
      .post(`${this._utils.url}Transportista/confirmarDocumentos` ,json, {headers: this.headers})
      .map((response) => {
        try {
          if (response.json() !== 'null') {
            console.log(response.text());
          return JSON.parse(response.text());
          } else {
            return null;
          }
        } catch (e) {
          return null;
        }
      });
  }

  validarParametroEnviarDocumentos(idRazonSocial){
    return this._http.get(`${this._utils.url}Ruta/obtenerParametroPlannerRoutech?idRazonSocial=`+idRazonSocial).map(
      (response) => {
        try {
          return JSON.parse(response.text());
        } catch (e) {
          return null;
        }
      });
  }

  obtenerArchivosRuta(idRuta, idRazonSocial){
    let params = {
      idRuta: idRuta,
      idRazonSocial: idRazonSocial
    }
    const json = JSON.stringify(params);
    console.log(this._utils.url);
    return this._http.post(`${this._utils.url}Ruta/obtenerArchivosRuta/`, json, { headers: this.headers }).map(
      (response) => {
        try {
          return JSON.parse(response.text());
        }catch(e){
          return [];
        }
      });
  }

  agreagarArchivoRuta(archivo : any){
    let params = JSON.stringify(archivo);
    return this._http.post(this._utils.url + "Ruta/agreagarArchivoRuta", params, {headers: this.headers}).map(resp =>{
         return resp.json();
    })
  }

  consultarRutasExportar(rutas){
    const json = JSON.stringify({ rutas });
    return this._http.post(`${this._utils.url}Ruta/consultarRutasExportar/`, json, { headers: this.headers }).map(
      (response) => {
        try {
          return JSON.parse(response.text());
        }catch(e){
          return [];
        }
      });
  }

}
